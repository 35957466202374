import PropTypes from 'prop-types';
import Page from '@/components/app/Page';
import Meta from '@/components/app/Meta';
import ErrorFullPage from '@/components/app/Error/ErrorFullPage';
import { standardisedGetServerSideProps } from '@/helpers/ssr';
import NotFoundFullPage from '@/components/app/Error/NotFoundFullPage';
import { isEqualAsNumber } from '@/helpers/math';

const Error = ({ statusCode }) => {
  const is404 = isEqualAsNumber(statusCode, 404);
  const content = is404 ? <NotFoundFullPage /> : <ErrorFullPage />;

  return (
    <Page meta={ <Meta disableIndexing disableSharingTags title={ is404 ? '404' : 'Error' } /> } pageId='error'>
      { content }
    </Page>
  );
};

Error.propTypes = { statusCode: PropTypes.number };

Error.defaultProps = { statusCode: undefined };

export const getServerSideProps = standardisedGetServerSideProps(
  {
    getServerSideProps: ({ res, err }) => {
      const errorStatusCode = (err ? err.statusCode : 404);

      const statusCode = res
        ? res.statusCode
        : errorStatusCode;

      return { props: { statusCode } };
    }
  }
);

export default Error;